<template>
  <Layout>
    <el-tabs v-model="tabsActiveName">
      <el-tab-pane
        label="测试用户"
        name="测试用户"
      >
        <div class="head">
          <div
            v-loading="testUser.loading"
            class="overview"
          >
            <div class="items">
              <div class="num">
                {{ testUser.total || '-' }}
              </div>
              <div class="name">
                测试用户数
              </div>
            </div>
          </div>
          <div>
            <el-button @click="testUser.dialogFormVisible = true">
              添加
            </el-button>
          </div>
        </div>

        <el-table
          :data="testUser.list"
          style="width: 100%"
        >
          <el-table-column
            prop="user_id"
            label="user_id"
          />
          <el-table-column
            prop="create_time"
            label="标记时间"
          />
          <el-table-column
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                size="small"
                @click="testUserDel(scope.row.user_id)"
              >
                删除标记
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      title="测试用户"
      :visible.sync="testUser.dialogFormVisible"
    >
      <el-form :model="testUser.formData">
        <el-form-item
          label="用户ID(多个用;隔开)"
        >
          <el-input
            v-model="testUser.formData.user_ids"
          />
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="testUser.dialogFormVisible = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="testUserAdd()"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </Layout>
</template>

<script>
import {
  goodsTypeList,
  goodsTypesMap,
  chargeSceneList,
  chargeSceneMap,
  appIdMap,
  goodsChannelList,
  goodsChannelMap,
  CountryAbbreviation
} from '@/utils/selectOptions'
import { orderRefund, queryOrderList, testUserList, testUserAdd, testUserDel } from '@/api/transaction-management'
import { format } from 'fecha'
import { cloneDeep } from 'lodash'
import { batchQueryParamsForExport } from '@/utils'
import { downloadExcel } from '@/components/UploadExcel/downloadExcel'
// import { getYesterdaySection } from '@/utils'
import { getAppKeys } from '@/api/blacklist.js'
export default {
  name: 'OrderList',
  data () {
    return {
      tabsActiveName: '测试用户',
      visible: false,
      goodsTypeList,
      goodsTypesMap,
      chargeSceneList,
      chargeSceneMap,
      appIdMap,
      goodsChannelList,
      goodsChannelMap,
      CountryAbbreviation,
      appKeysList: [],
      formData: {
        testUserType: 1,
        block: '',
        thirdOrderId: '',
        goodsName: '',
        payType: [],
        chargeScene: [],
        appId: [],
        appKey: [],
        version: '',
        minPrice: 0.0,
        maxPrice: 0.0,
        pageNum: 1,
        pageSize: 20
      },
      datetimerange: this.getYesterdaySection(),
      orderListInfo: {
        loading: false,
        total: 0,
        priceTotal: 0,
        list: []
      },
      orderStatusList: { 1: '已下单', 3: '已支付', 7: '已退款' },
      formDataRefund: {
        orderId: 0,
        refundMoney: 0
      },
      batchUploadTemplate: {
        list: [],
        header: [
          '地区',
          '用户ID',
          '订单号',
          '第三方订单号',
          '商品名称',
          '商品ID',
          '第三方商品ID',
          '付款金额(USD)',
          '付款金额',
          '付款货币',
          '付款方式',
          '付款状态',
          '充值场景',
          '应用',
          '版本号',
          '交易时间(世界时间UTC时间)'
        ],
        fields: [
          'nation',
          'userId',
          'orderId',
          'thirdOrderId',
          'goodsName',
          'goodsId',
          'thirdGoodsId',
          'usdPrice',
          'price',
          'currency',
          'payType',
          'orderStatus',
          'chargeScene',
          'appId',
          'version',
          'tradeTime'
        ]
      },
      testUser: {
        dialogFormVisible: false,
        loading: false,
        formData: {
          user_ids: ''
        },
        list: [],
        total: 0
      }
    }
  },
  created () {
    this.queryOrderList()
    this.getAppKeys()
    this.testUserList()
  },
  methods: {
    getYesterdaySection (dateFormat = 'YYYY-MM-DD HH:mm:ss') {
      const start = new Date(new Date(new Date().getTime() - 8 * 3600000).toLocaleDateString()).getTime()
      const end = new Date(new Date()).getTime()
      if (dateFormat === 'timestamp') {
        return [start, end]
      }
      return [format(start, dateFormat), format(end - (8 * 3600000), dateFormat)]
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryOrderList()
    },
    queryOrderList () {
      this.orderListInfo.loading = true
      let startTime
      let endTime
      if (this.datetimerange && this.datetimerange.length) {
        startTime = this.datetimerange[0]
        endTime = this.datetimerange[1]
      }
      queryOrderList({
        ...this.formData,
        startTime,
        endTime
      })
        .then(res => {
          this.orderListInfo.list = res.data.list
          this.orderListInfo.total = res.data.total
          this.orderListInfo.priceTotal = res.data.priceTotal
        })
        .finally(() => {
          this.orderListInfo.loading = false
        })
    },
    getAppKeys () {
      getAppKeys({
        platform: ''
      }).then(res => {
        if (res.code === 200) {
          this.appKeysList = res?.data?.list ?? []
          this.appKeysNameMap = this.appKeysList.reduce((result, item) => {
            result[item.appKey] = item.name
            return result
          }, {})
        }
      })
    },
    showRefundBox (row) {
      this.formDataRefund.orderId = row.orderId
      this.formDataRefund.refundMoney = row.price
      this.stopScroll()
    },
    refundFun (index, row) {
      console.log(this.formDataRefund)
      orderRefund(this.formDataRefund).then(res => {
        if (res.code === 200) {
          if (res.data.success) {
            this.$message.success('退款成功！')
          } else {
            this.$message.error('退款失败！')
          }
          this.$refs[`popover-${row.orderId}`].doClose()
          this.queryOrderList()
        }
      })
      this.canScroll()
    },
    stopScroll () {
      var mo = function (e) { e.preventDefault() }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', mo, false)
    },
    canScroll () {
      var mo = function (e) { e.preventDefault() }
      document.body.style.overflow = ''
      document.removeEventListener('touchmove', mo, false)
    },
    handleExport () {
      this.listForExport()
        .then(data => {
          if (data) {
            this.formatToDwonload(data)
            this.downloadExcel()
          }
        })
    },
    formatToDwonload (data) {
      data.map(item => {
        item.payType = (goodsChannelMap[item.payType] || {}).name || item.payType
        item.orderStatus = this.orderStatusList[item.orderStatus]
        item.appId = (appIdMap[item.appId] || {}).name || '未知'
        item.usdPrice = (item.usdPrice).toFixed(2)
        return item
      })
      this.batchUploadTemplate.list = data
    },
    downloadExcel () {
      const header = this.batchUploadTemplate.header
      const list = this.batchUploadTemplate.list
      const fields = this.batchUploadTemplate.fields
      downloadExcel(list, header, fields, '交易订单' + '_' + new Date().toLocaleDateString())
    },
    listForExport () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.orderListInfo.loading = true
            const formData = cloneDeep(this.formData)
            const paramsList = batchQueryParamsForExport({
              params: {
                ...formData,
                pageSize: 100,
                startTime: this.datetimerange[0],
                endTime: this.datetimerange[1]
              },
              total: this.orderListInfo.total
            })
            Promise.all(paramsList.map(params => queryOrderList({ ...params, pageNum: params.page })))
              .then(resultList => {
                const result = resultList.reduce((res, item) => {
                  return res.concat(item.data.list)
                }, [])
                resolve(result)
              })
              .finally(() => {
                this.orderListInfo.loading = false
              })
          } else {
            resolve(false)
          }
        })
      })
    },
    testUserList () {
      this.testUser.loading = true
      testUserList({ page: 1, size: 1000000 })
        .then(res => {
          this.testUser.list = res.data.list
          this.testUser.total = res.data.total
        })
        .finally(() => {
          this.testUser.loading = false
        })
    },
    testUserAdd () {
      testUserAdd(this.testUser.formData)
        .then(res => {
          if (res.data.success) {
            this.$notify({
              title: '成功',
              message: '添加成功',
              type: 'success'
            })
            this.testUserList()
          } else {
            this.$notify.error({
              title: '添加失败',
              message: res.data.msg
            })
          }
        }).finally(() => {
          this.testUser.dialogFormVisible = false
        })
    },
    testUserDel (userId) {
      testUserDel({ user_id: userId })
        .then(res => {
          if (res.data.success) {
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success'
            })
            this.testUserList()
          } else {
            this.$notify.error({
              title: '删除失败',
              message: res.data.msg
            })
          }
        })
    }
  }
}
</script>

<style scoped lang="less">
  .overview {
    display: flex;
    min-width: 880px;
    margin-bottom: 10px;
    .items {
      padding: 0 30px;
      height: 100px;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
      border-radius: 4px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .num {
        font-size: 26px;
        margin-bottom: 10px;
        .unit {
          font-size: 14px;
        }
      }
      .name {
        font-size: 12px;
      }
    }
    }
    .head {
      display: flex;
      justify-content: space-between;
    }
</style>
